<template>
  <div class="outer-page">
    <div class="center-width">
      <div class="content">
        <div v-html="list.articleContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      list: [],
    };
  },
  computed: {
    //获取菜单
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    //获取当前的router
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  methods: {
    getList() {
      if (Object.keys(this.getSubMenu).length == 0) return;
      let child = this.getSubMenu.childList.filter(
        (item) => item.router == this.getCurrentRoute
      )[0];
      if (child.Interface) {
        this.$post(child.Interface, {
          pageSize: 10,
          pageNo: 1,
        }).then((res) => {
          this.list = res;
        });
      }
    },
  },
  mounted() {
    this.getList();
  },
  watch: {
    $route() {
      this.getList();
    },
    getSubMenu: {
      deep: true,
      handler() {
        this.getList();
      },
    },
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  padding: 0.5rem 0;
  font-size: 0.577rem;
  line-height: 1rem;
  .center-width {
    width: 94%;
    margin: 0 3%;
    .content {
      font-size: 0.577rem;
      line-height: 0.88rem;
      color: #666666;
      //   padding-top: 0.5rem;
    }
  }
}
</style>